import React from "react"
import SEO from "../components/seo"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "react-image-lightbox/style.css"
import { Layout } from "../components/parts/Layout"
import { ProjectWrapper } from "../components/project/ProjectWrapper"


const Project = () => {

  return (
    <Layout>
      <ProjectWrapper/>
    </Layout>
  )
}

export default Project
