import React, { useEffect, useState } from "react"
import { isEmpty } from "lodash"
import { ProjectBanner } from "../ProjectBanner"
import { ProjectGrid } from "../ProjectGrid"
import { ContactUs } from "../../main-page/ContactUs"
import axios from "axios"
import SEO from "../../seo"
import { Layout } from "../../parts/Layout"

const ProjectWrapper = () => {
  const [projectData, setProjectData] = useState(null)


  useEffect(() => {
    (async () => {

      const urlSearchQuery = window.location.search
      if (!urlSearchQuery) return
      const urlID = urlSearchQuery.split("?id=")[1]

      const url = process.env.REACT_URL
      await axios({
        method: "GET",
        url: `${url}/gallery/building/${urlID}`,
      }).then(project => setProjectData(project.data))
    })()

  }, [])

  return (
    <>
      {!isEmpty(projectData) && (
        <>
          <SEO title={projectData.name} titleTemplate={projectData.name} />
          <ProjectBanner projectData={projectData}/>
          <ProjectGrid projectData={projectData}/>
          <ContactUs/>
        </>
      )}
    </>
  )
}

export default ProjectWrapper
