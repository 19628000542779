import React, { useState } from "react"
import Lightbox from "react-image-lightbox"
import "./ProjectGrid.scss"

function ProjectGrid({ projectData }) {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const { gallery } = projectData

  const handleClick = (index) => {
    setPhotoIndex(index)
    setIsOpen(true)
  }

  const handleOpen = () => {
    const element = document.querySelector(".ril-inner.ril__inner")
    element.oncontextmenu = function() {
      return false
    }
    console.log(element)

  }

  return (
    <section className="ProjectGrid">
      <div className="container">
        <div className="ProjectGrid-wrapper">
          {
            gallery.map((project, index) => (
              <div
                key={project.id}
                className="ProjectGrid-box"
                onClick={() => handleClick(index)}
              >
                <div className="ProjectGrid-bg"
                     style={{ backgroundImage: `url('${project.image}` }}/>
              </div>
            ))
          }
        </div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={gallery[photoIndex].image}
          nextSrc={gallery[(photoIndex + 1) % gallery.length].image}
          prevSrc={gallery[(photoIndex + gallery.length - 1) % gallery.length].image}
          onCloseRequest={() => setIsOpen(false)}
          onAfterOpen={handleOpen}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % gallery.length)
          }
        />
      )}
    </section>
  )
}

export default ProjectGrid
