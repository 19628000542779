import React from "react"
import Slider from "react-slick"
import { CustomCarouselArrow } from "../../CustomCarouselArrow"
import "./ProjectBanner.scss"


function ProjectBanner({ projectData }) {
  const { name, gallery, square } = projectData

  const settings = {
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 6500,
    slidesToShow: 1,
    fade: true,
    lazyLoad: true,
    prevArrow: <CustomCarouselArrow position="left"/>,
    nextArrow: <CustomCarouselArrow/>,
  }

  return (
    <section className="ProjectBanner">
      <Slider {...settings}>
        {
          gallery.map(item => (
            item.is_poster ? (
              <div key={item.id} className="ProjectBanner-slide">
                <div className="ProjectBanner-content">
                  <h1 className="title">
                    Дизайн интерьера
                  </h1>
                  <p className="building">
                    {name}
                  </p>
                  <p className="square">
                    Площадь - {square} кв. м.
                  </p>
                </div>
                <div className="ProjectBanner-image" style={{ backgroundImage: `url(${item.image})` }}/>
              </div>
            ) : null
          ))
        }
      </Slider>
    </section>
  )

}

export default ProjectBanner
